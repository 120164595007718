/* eslint-disable @typescript-eslint/no-explicit-any */

declare global {
  interface Window {
    _hsp: any[]
    _hsq: any[]
    disableHubSpotCookieBanner: boolean
  }
}

if (typeof window !== 'undefined') {
  window.disableHubSpotCookieBanner = true
}

export const getHsp = (): any[] =>
  typeof window !== 'undefined' ? (window._hsp ?? []) : []

export const getHsq = (): any[] =>
  typeof window !== 'undefined' ? (window._hsq ?? []) : []

/* eslint-disable tailwindcss/migration-from-tailwind-2 */
import { useState } from 'react'

export type IPreferences = {
  analytics: boolean
  advertisement: boolean
  functionality: boolean
}

type IProps = {
  onSave: (preferences: IPreferences) => void
  onClose: () => void
}

const ManageCookiesModal: React.FC<IProps> = ({ onSave, onClose }) => {
  const [preferences, setPreferences] = useState<IPreferences>({
    analytics: true,
    advertisement: true,
    functionality: true
  })

  const handleToggle = (
    type: 'analytics' | 'advertisement' | 'functionality'
  ) => {
    setPreferences((prev) => ({ ...prev, [type]: !prev[type] }))
  }

  return (
    <div className="fixed bottom-4 left-1/2 z-[1201] w-11/12 max-w-[500px] -translate-x-1/2 rounded-lg bg-white-500 p-6 shadow-lg transition-all duration-300 ease-in-out">
      <h3 className="text-xl font-bold text-black-500">Administrar cookies</h3>
      <p className="mt-2 text-sm leading-relaxed text-black-400">
        Selecciona las cookies que deseas permitir:
      </p>
      <div className="mt-4">
        <div className="flex items-center justify-between">
          <span className="text-black-500">Cookies de análisis</span>
          <input
            type="checkbox"
            checked={preferences.analytics}
            onChange={() => handleToggle('analytics')}
            className="size-4"
            aria-label="Toggle Analytics Cookies"
          />
        </div>
        <div className="mt-3 flex items-center justify-between">
          <span className="text-black-500">Cookies de publicidad</span>
          <input
            type="checkbox"
            checked={preferences.advertisement}
            onChange={() => handleToggle('advertisement')}
            className="size-4"
            aria-label="Toggle Advertisement Cookies"
          />
        </div>
        <div className="mt-3 flex items-center justify-between">
          <span className="text-black-500">Cookies de funcionalidad</span>
          <input
            type="checkbox"
            checked={preferences.functionality}
            onChange={() => handleToggle('functionality')}
            className="size-4"
            aria-label="Toggle Functionality Cookies"
          />
        </div>
      </div>
      <div className="mt-6 flex flex-col items-stretch gap-3 sm:flex-row sm:justify-end">
        <button
          onClick={onClose}
          className="rounded bg-grey-300 px-4 py-2 text-black-400 hover:bg-grey-400 focus:outline-none focus:ring focus:ring-grey-400"
        >
          Cancelar
        </button>
        <button
          onClick={() => onSave(preferences)}
          className="rounded bg-blue-500 px-4 py-2 text-white-500 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300"
        >
          Guardar preferencias
        </button>
      </div>
    </div>
  )
}

export default ManageCookiesModal

import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import config from '../config'

if (!firebase.apps.length) {
  firebase.initializeApp(config.firebaseClientCredentials)
}

firebase.auth().languageCode = 'es'

export default firebase

import { Unmasked, useApolloClient } from '@apollo/client'
import { DocumentNode } from 'graphql'
import { useCallback } from 'react'

type IUpdateCacheProps<TVariables> = {
  query: DocumentNode
  variables?: TVariables
}

function useCacheUpdater<TData, TVariables>({
  query,
  variables
}: IUpdateCacheProps<TVariables>) {
  const client = useApolloClient()

  const updateCache = useCallback(
    (updateData: (data: Unmasked<TData>) => Unmasked<TData>) => {
      const data = client.readQuery<TData, TVariables>({ query, variables })

      if (!data) return

      client.writeQuery<TData, TVariables>({
        query,
        variables,
        data: updateData(data)
      })
    },
    [client, query, variables]
  )

  return updateCache
}

export default useCacheUpdater
